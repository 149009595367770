import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Datatable, {
        "table-data": _ctx.myJobs,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true
      }, {
        "cell-name": _withCtx(({ row: job }) => [
          _createTextVNode(_toDisplayString(job.type), 1)
        ]),
        "cell-client": _withCtx(({ row: job }) => [
          _createTextVNode(_toDisplayString(job.name), 1)
        ]),
        "cell-status": _withCtx(({ row: job }) => [
          _createTextVNode(_toDisplayString(job.status), 1)
        ]),
        "cell-result": _withCtx(({ row: job }) => [
          _createTextVNode(_toDisplayString(job.result), 1)
        ]),
        _: 1
      }, 8, ["table-data", "table-header"])
    ])
  ]))
}