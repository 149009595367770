
import {defineComponent, ref, reactive, computed, onMounted, watch} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import {Job, Client} from "@/store/modules/ApiModule";


export default defineComponent({
  name: "jobs-listing",
  components: {
    Datatable
  },
  setup() {
    const tableHeader = ref([
      {
        name: "Type",
        key: "type",
        sortable: false
      },
      {
        name: "Name",
        key: "name",
        sortable: false,
      },
      {
        name: "Status",
        key: "status",
        sortable: false,
      },
      {
        name: "Result",
        key: "result",
        sortable: false,
      }
    ]);

    const store = useStore();
    let myJobs = ref<Array<Job>>([]);
    let selectedItem = reactive({} as Job);

    let myData = computed(() => {
      return store.state.api.jobs;
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      store.dispatch(Actions.LOAD_JOBS);
    });

    watch(myData, (newValue, oldValue) => {
      myJobs.value.splice(0,myJobs.value.length)
      newValue.forEach(function (value) {
        myJobs.value.push(value);
      });
    });

    const search = ref<string>("");
    const searchItems = () => {
      /*tableData.value.splice(0, tableData.value.length, ...initClients.value);
      if (search.value !== "") {
        let results: Array<Client> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }*/
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      myData,
      myJobs,
      selectedItem,
      tableHeader,
      search,
      searchItems
    };
  },
});
