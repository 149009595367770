import {BooleanSelect} from "@/store/modules/ApiModule";

const booleanSelect: Array<BooleanSelect> = [
    {
        id: true,
        name: "Yes"
    },
    {
        id: false,
        name: "No"
    }
];

export default booleanSelect;
