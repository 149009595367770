
import {defineComponent, reactive, ref, toRefs, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import booleanSelect from "@/core/data/booleanselect";
import {UaProject} from "@/store/modules/ApiModule";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "update-ua-report-modal",
  components: {},
  props: {
    uaproject: {
      type: Object as () => UaProject,
      required: true
    }
  },
  setup(props) {

    const formRef = ref<null | HTMLFormElement>(null);
    const updateUaReportModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    let formData = reactive({
      dates: [],
      job: false,
      job_name: ""
    });
    const store = useStore();

    const rules = ref({});

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          let payload = {
            id: props.uaproject.id,
            from: formData.dates[0],
            to: formData.dates[1],
            job: formData.job,
            job_name: formData.job_name
          }
          loading.value = true;
          ApiService.setHeader();
          return ApiService.post("uaprojects/report", payload)
            .then((response) => {
              let messageText = "";
              if(response.data.code == 200) messageText = response.data.numUpdatedRows + " rows have been updated"
              else messageText = response.data.message;
              loading.value = false;
              Swal.fire({
                text: messageText,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                if(formRef.value){
                  formRef.value.resetFields();
                }
                hideModal(updateUaReportModalRef.value);
              });
            })
            .catch((error) => {
              loading.value = false;
              Swal.fire({
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      submit,
      booleanSelect,
      formRef,
      loading,
      updateUaReportModalRef,
    };
  },
});
